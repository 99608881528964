import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, Subject, takeUntil } from 'rxjs';
import { Constants } from './configs/app.config';
import { AnalyticsService } from './core/services/analytics.service';
import { AuthService } from './core/services/auth.service';
import { NetworkStatusService } from './core/services/network-status.service';
import { isMobileApp } from './shared/utils/utils.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'ohmPayWeb';
  isOnline: boolean = true;
  _onDestroy = new Subject<void>();
  navigationHistory: string[] = [];

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private appConstants: Constants,
    private router: Router,
    private location: Location,
    private networkStatusService: NetworkStatusService,
    private analyticsService: AnalyticsService
  ) {
    this.translateService.use(this.appConstants.defaultLanguage);
    this.router.events
      .pipe(takeUntil(this._onDestroy))
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event) => {
        if (
          !event.urlAfterRedirects.startsWith('/login?') &&
          (!this.navigationHistory.length ||
            !this.navigationHistory.includes(event.urlAfterRedirects))
        ) {
          this.navigationHistory.push(event.urlAfterRedirects);
        }
        if (
          this.navigationHistory.length > 1 &&
          this.navigationHistory.includes(event.urlAfterRedirects) &&
          this.navigationHistory[this.navigationHistory.length - 1] !==
            event.urlAfterRedirects
        ) {
          do {
            this.popFromNavigationHistory();
          } while (
            this.navigationHistory.length > 1 &&
            this.navigationHistory[this.navigationHistory.length - 1] !==
              event.urlAfterRedirects
          );
        }
        this.analyticsService.trackPageView(event.urlAfterRedirects);
      });

    this.location.subscribe((event) => {
      if (event.type === 'popstate') {
        this.popFromNavigationHistory();
      }
    });

    if (isMobileApp()) {
      this.networkStatusService.isOnline
        .pipe(takeUntil(this._onDestroy))
        .subscribe((status) => {
          this.isOnline = status;
        });
    }
  }

  popFromNavigationHistory() {
    this.navigationHistory.pop();
    if (this.navigationHistory.length === 1) {
      history.back();
    }
  }

  refresh(): void {
    window.location.reload();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
